@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .root-style {
    @apply flex w-4/5 pl-5 py-10 bg-bgclr8;
  }

  .input-style {
    @apply mt-1 p-2 w-full border rounded-md focus:border-gray-400 ring-1 ring-gray-300;
  }

  .li-style {
    @apply hover:bg-bgclr8 hover:text-bgclr3 hover:border-b hover:border-gray-600 py-2 px-5;
  }

  .active {
    @apply bg-bgclr8 text-bgclr3 border-b border-gray-600;
  }

  .bankNavbarStyle {
    @apply bg-bgclr text-txtclr;
  }

  .searchBar-style {
    @apply flex justify-center text-[16px] w-[975px] my-6 mr-3 gap-4 text-bgclr;
  }

  .searchBar-input {
    @apply border border-[#EBEBEB] rounded-md w-full pl-6 pr-5 py-3;
  }

  .searchBar-btn {
    @apply bg-btnclr rounded-md px-6 font-[500] text-txtclr;
  }
}

.stdropdown-input {
  display: flex;
  border: 1.5px solid #0B1E59;
  border-radius: 5px;
  padding: 0.3em;
}

.stdropdown-input input {
  border-radius: 5px;
}

.stdropdown-tool {
  /* @media (min-width: 1024px) {
    margin-left: 1.5em;
  }
  @media (min-width: 340px) {
    margin-left: 8.5em;
  }
  padding: 0.5em; */
  display: none;
}

.stdropdown-menu {
  position: relative;

  @media (min-width: 768px) {
    position: absolute;
  }

  max-height: 250px;
  overflow-y: auto;
  width: 240px;
  border: 1px solid #0B1E59;
  padding: 0.5em;
  margin-left: 8px;
  background-color: white;
}

div.popup-overlay {
  position: absolute;
}

.react-calendar {
  border: none !important;
}

.swal2-actions .swal2-confirm {
  background-color: #1D4ED8;
}

.tox-statusbar__branding {
  display: none;
}

#swal2-title {
  font-size: 16px;
}

.swal2-error {
  width: 48px;
  height: 48px;
  margin-top: 20px;
}

.swal2-x-mark {
  font-size: 10px;
}

.swal2-popup {
  width: 486px;
  line-height: 5px;
  border-radius: 10px;
}

#swal2-title {
  font-size: 20px;
}

#swal2-html-container {
  font-size: 16px;
  margin-top: 20px;
}

.swal2-actions .swal2-confirm {
  width: 450px;
  background-color: #0B1E59 !important;
  color: white !important;
  font-weight: 500;
  font-size: 16px;
  margin-top: -10px;
  height: 5.5vh;
  border: none !important;
  box-shadow: none !important;
  border-radius: 8px;
  margin-bottom: -5px;
}